.thank-you-container {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
  }
  
  .thank-you-container h2 {
    color: #007bff;
  }
  
  .thank-you-container p {
    font-size: 18px;
  }
  