.survey-container {
  text-align: center;
  margin: 100px auto;
  width: 25%;
  max-width: 700px;
  padding: 20px;
  border-radius: 15px;
  /* background-color: #f9f9f9; */
  background: linear-gradient(to right, #00AAFF, #00FF6c);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for background and shadow */
}

.survey-container:hover {
  background-color: #f0f8ff; /* Lighten the background color on hover */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Enhance shadow effect on hover */
}

.rating-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  font-size: 25px;
}

.rating-label {
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.rating-label:hover {
  background-color: #f0f8ff;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, transform 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
  opacity: 0.8;
  transform: scale(1.05); /* Slight scale-up effect on hover */
}
.employee-name-input {
  margin-bottom: 15px;
}